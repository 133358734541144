/* eslint-disable camelcase */
// eslint-disable-next-line react/jsx-props-no-spreading

const tokenID = 'admin.auth.token';
const expiration = 'admin.auth.expire';
const email = 'admin.auth.email';
const name = 'admin.auth.name';
const profileImg = 'admin.auth.profileImg';

export default {
    getToken() {
        return JSON.parse(localStorage.getItem(tokenID));
    },
    getTokenExpiration() {
        return JSON.parse(localStorage.getItem(expiration));
    },
    getEmail() {
        return JSON.parse(localStorage.getItem(email)) || 'unit.test@fake.com';
    },

    getUsername() {
        return JSON.parse(localStorage.getItem(name)) || 'Fake User';
    },

    getProfileImg() {
        return JSON.parse(localStorage.getItem(profileImg)) || 'profilePic';
    },
    storeGoogleResponseObject(GoogleObj) {
        localStorage.setItem(tokenID, JSON.stringify(GoogleObj.tokenObj.id_token));
        localStorage.setItem(expiration, JSON.stringify(GoogleObj.tokenObj.expires_in));
        localStorage.setItem(email, JSON.stringify(GoogleObj.profileObj.email));
        localStorage.setItem(name, JSON.stringify(GoogleObj.profileObj.name));
        localStorage.setItem(profileImg, JSON.stringify(GoogleObj.profileObj.imageUrl));
    },
    clearAttempt() {
        localStorage.clear();
    },
};
