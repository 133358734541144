import React from 'react';
import ChartistGraph from 'react-chartist';
import './Chart.scss';

// eslint-disable-next-line react/prefer-stateless-function
class Line extends React.Component {
    render() {
        const { input } = this.props;

        const data = {
            labels: [
                '7 AM',
                '8 AM',
                '9 AM',
                '10 AM',
                '11 AM',
                '12 PM',
                '1 PM',
                '2 PM',
                '3 PM',
                '4 PM',
                '5 PM',
                '6 PM',
            ],
            series: [input],
        };

        const options = {
            width: '100%',
            height: '22%',
            high: 8,
            low: 0,
            axisX: {
                labelInterpolationFnc(value, index) {
                    return index % 2 === 0 ? value : null;
                },
            },
        };

        const type = 'Line';
        const aspectRatio = 'ct-octave';

        return (
            <div className="Chart">
                <h1 className="LineTitle">Busiest Times</h1>
                <ChartistGraph className={aspectRatio} data={data} options={options} type={type} />
            </div>
        );
    }
}
// eslint-disable-next-line react/prefer-stateless-function
export default Line;
