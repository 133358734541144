import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';

import authService from '../AuthService';
import ApiFunctions from '../api/WelcomeAdminAPI';
import Button from '../button/Button';
import './EditPage.scss';

export default ({ match = {} }) => {
    const { id } = match.params || 0;
    const [logEntry, setLogEntry] = useState({});
    const [state, setState] = useState({});
    const history = useHistory();

    useEffect(() => {
        async function getList() {
            const list = await ApiFunctions.getEntry(id);

            setLogEntry(list[0]);
        }
        getList();
    }, [id]);

    useEffect(() => {
        if (logEntry?.id) {
            setState({
                id: logEntry.id,
                first_name: logEntry.first_name,
                last_name: logEntry.last_name,
                company_name: logEntry.company_name,
                citizenship: logEntry.citizenship,
                reason_visiting: logEntry.reason_visiting,
                employee_escort: logEntry.employee_escort,
                location: logEntry.location,
                login_date: moment(logEntry.login_date).format('YYYY-MM-DDTHH:mm'),
                logout_date: moment(logEntry.logout_date).format('YYYY-MM-DDTHH:mm'),
                edited_by: authService.getUsername(),
            });
        }
    }, [logEntry]);

    const handleInputChange = e => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const sendEdit = async () => {
        await ApiFunctions.sendEntry(state);
        history.push('/logs');
    };

    return (
        <div className="EditPage">
            <h1>Edit Entry</h1>
            <form>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="fname">First name</label>
                        <input
                            type="text"
                            id="fname"
                            name="first_name"
                            defaultValue={state.first_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="Group">
                        <label htmlFor="lname">Last name</label>
                        <input
                            type="text"
                            id="lname"
                            name="last_name"
                            defaultValue={state.last_name}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="company">Company</label>
                        <input
                            type="text"
                            id="company"
                            name="company_name"
                            defaultValue={state.company_name}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="Group">
                        <label htmlFor="citizenship">Citizenship</label>
                        <input
                            type="text"
                            id="citizenship"
                            name="citizenship"
                            defaultValue={state.citizenship}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

                <div className="Row">
                    <div className="Group">
                        <label htmlFor="visiting">Escort</label>
                        <input
                            name="employee_escort"
                            defaultValue={state.employee_escort}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="Group">
                        <label htmlFor="reason">Reason for Visiting</label>
                        <input
                            name="reason_visiting"
                            defaultValue={state.reason_visiting}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <div className="Textbox">
                            <label htmlFor="date">Time Signed In</label>
                            <input
                                type="datetime-local"
                                id="date"
                                name="login_date"
                                onChange={handleInputChange}
                                defaultValue={state.login_date}
                            />
                        </div>
                    </div>
                    <div className="Group">
                        <div className="Textbox">
                            <label htmlFor="date">Time Signed Out</label>
                            <input
                                type="datetime-local"
                                id="date"
                                name="logout_date"
                                onChange={handleInputChange}
                                defaultValue={state.logout_date}
                            />
                        </div>
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="location">Location</label>
                        <input
                            name="location"
                            defaultValue={state.location}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </form>
            <div className="Buttons">
                <Link className="SubmitButton" to="/logs">
                    <Button variant="primary" href="/logs" onClick={sendEdit}>
                        SUBMIT
                    </Button>
                </Link>
                <Link className="CancelButton" to="/logs">
                    <Button variant="primary">CANCEL</Button>
                </Link>
            </div>
        </div>
    );
};
