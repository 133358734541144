import React from 'react';

import SortIcons from './SortIcons';

const TableHeaderCell = props => {
    const { field, label, sortField, sortable, sortDirection, onChangeSort } = props;

    const handleSort = () => {
        // sortField === column.field
        // cycle sort direction
        if (sortField === field) {
            let nextDir = null;
            if (!sortDirection) {
                nextDir = 'asc';
            } else if (sortDirection === 'asc') {
                nextDir = 'desc';
            }
            // setSortDirection(nextDir);
            if (onChangeSort) {
                onChangeSort({ field, direction: nextDir });
            }
        } else if (onChangeSort) {
            // new sortField
            // reset sort direction to asc
            onChangeSort({ field, direction: 'asc' });
        }
    };

    // th element class
    let headerClass = null;
    if (sortable) {
        headerClass = 'Table__header-sortable';
    }

    // sort indicator
    let sortIndicator = null;
    if (sortField === field && sortDirection) {
        if (sortDirection === 'asc') {
            sortIndicator = <SortIcons.SortAscending />;
        } else {
            sortIndicator = <SortIcons.SortDescending />;
        }
    } else if (sortable) {
        // sortable indicator
        sortIndicator = <SortIcons.Sortable />;
    }

    return (
        <th
            key={field}
            className={headerClass}
            onClick={() => {
                if (sortable) {
                    handleSort();
                }
            }}
        >
            <span>
                {label} {sortIndicator}
            </span>
        </th>
    );
};

export default TableHeaderCell;
