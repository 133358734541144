import React from 'react';
import moment from 'moment';
import Table from '../../table/Table';

export default props => {
    const { input } = props;
    const visitorList = input;

    const dataWithNulls = visitorList.filter(visitor => visitor.logout_date === null);

    const columns = [
        {
            label: 'Name',
            field: 'first_name',
            renderer: columnProps => {
                const { row } = columnProps;
                return (
                    <>
                        {row.first_name} {row.last_name}
                    </>
                );
            },
        },
        {
            label: 'Time In',
            field: 'login_date',
            renderer: columnProps => {
                const { row } = columnProps;
                return <>{moment(row.login_date).format('LT')}</>;
            },
        },
    ];

    let content = <p>Currently, there are no visitors.</p>;
    if (dataWithNulls.length >= 1) {
        content = <Table columns={columns} data={dataWithNulls} />;
    }
    return (
        <div className="SignedIn">
            <h1>Signed In</h1>
            <div className="SignedIn__Table">{content}</div>
        </div>
    );
};
