import React from 'react';
import Clock from '../../../logo/ClockIcon';
import '../Tiles.scss';

export default props => {
    const { input } = props;
    const data = input;

    return (
        <div className="TimeInOffice">
            <div className="TimeInOffice__ClockIcon">
                <Clock />
            </div>
            <div className="TimeInOffice__Words">
                <h1>{data} Minutes</h1>
                <p>Avg. Time in Office</p>
            </div>
        </div>
    );
};
