import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import ApiFunctions from '../api/WelcomeAdminAPI';
import Filter from '../Filter/filter';
import 'react-dropdown/style.css';
import Table from '../table/Table';
import Button from '../button/Button';

import './LogPage.scss';

export default () => {
    const [visitorList, setVisitorList] = useState([]);

    const [filteredVisitorList, setFilteredVisitorList] = useState([]);
    const [apiResponse, setApiResponse] = useState([]);

    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getAllVisitors();

            if (response.error) {
                setVisitorList([]);
                setFilteredVisitorList([]);
            } else {
                setVisitorList(response);
                setFilteredVisitorList(response);
            }

            setApiResponse(response);
        }

        getList();
    }, []);

    const columns = [
        {
            label: 'Name',
            field: 'first_name',
            renderer: columnProps => {
                const { row } = columnProps;
                const { id } = row;
                return (
                    <Link className="NameLink" to={`/logs/edit/${id}`}>
                        {row.first_name} {row.last_name}
                    </Link>
                );
            },
        },
        {
            label: 'Date',
            field: 'date',
            renderer: columnProps => {
                const { row } = columnProps;
                return <>{moment(row.login_date).format('MM-DD-YYYY')}</>;
            },
        },
        {
            label: 'Company',
            field: 'company_name',
        },
        {
            label: 'Location',
            field: 'location',
        },
        {
            label: 'Visiting',
            field: 'employee_escort',
        },
        {
            label: 'Reason',
            field: 'reason_visiting',
        },
        {
            label: 'Citizenship',
            field: 'citizenship',
        },
        {
            label: 'Time In',
            field: 'login_date',
            renderer: columnProps => {
                const { row } = columnProps;
                return <>{moment(row.login_date).format('LT')}</>;
            },
        },
        {
            label: 'Time Out',
            field: 'logout_date',
            renderer: columnProps => {
                const { row } = columnProps;
                return <>{moment(row.logout_date).format('LT')}</>;
            },
        },
        {
            label: 'Edited By',
            field: 'edited_by',
            renderer: columnProps => {
                const data = columnProps.row.edited_by;
                let stringBuilder = '';
                if (data) {
                    for (let i = 0; i < data.length; i += 1) {
                        // const cutString = data[i].editor.slice(0, -11);
                        const formatted = moment(data[i].timestamp).format('MM-DD-YYYY');

                        if (i === 0) {
                            stringBuilder += `by ${data[i].editor} on ${formatted}`;
                        } else {
                            stringBuilder += `, by ${data[i].editor} on ${formatted}`;
                        }
                    }
                    return <>{stringBuilder}</>;
                }
                return <></>;
            },
        },
    ];

    // let test = visitorList.length - 1;

    // console.log(visitorList[0]);

    function change(filtervalues) {
        const temp = [];
        visitorList.forEach(element => {
            if (
                filtervalues.searchbox === '' ||
                (element.first_name != null &&
                    element.first_name
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.last_name != null &&
                    element.last_name
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.date != null &&
                    element.date.toUpperCase().includes(filtervalues.searchbox.toUpperCase())) ||
                (element.company_name != null &&
                    element.company_name
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.location != null &&
                    element.location
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.employee_escort != null &&
                    element.employee_escort
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.reason_visiting != null &&
                    element.reason_visiting
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.citizenship != null &&
                    element.citizenship
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.login_date != null &&
                    element.login_date
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase())) ||
                (element.logout_date != null &&
                    element.logout_date
                        .toUpperCase()
                        .includes(filtervalues.searchbox.toUpperCase()))
                //         ||
                // (element.edited_by != null &&
                //     element.edited_by.toUpperCase().includes(filtervalues.searchbox.toUpperCase()))
            ) {
                if (
                    filtervalues.startdate === '' ||
                    (moment(element.login_date).isSameOrAfter(
                        moment(filtervalues.startdate),
                        'day'
                    ) &&
                        moment(filtervalues.enddate).isSameOrAfter(
                            moment(element.login_date),
                            'day'
                        ))
                ) {
                    if (
                        filtervalues.location === '' ||
                        element.location === filtervalues.location
                    ) {
                        if (
                            filtervalues.visiting === '' ||
                            element.employee_escort === filtervalues.visiting
                        ) {
                            if (
                                filtervalues.reason === '' ||
                                element.reason_visiting === filtervalues.reason
                            ) {
                                temp.push(element);
                            }
                        }
                    }
                }
            }
        });

        setFilteredVisitorList(temp);
    }
    if (apiResponse.error) {
        return <div className="LogError"> Sorry, the Log Data could not be retrieved. </div>;
    }
    return (
        <div>
            <div className="LogPage">
                <Filter
                    onChange={filtervalues => {
                        change(filtervalues);
                    }}
                />
                <Table columns={columns} data={filteredVisitorList} />
                <div className="CSVButton">
                    {visitorList.length > 0 && (
                        <CSVLink data={visitorList} filename="MileTwoLogs.csv" target="_blank">
                            <Button variant="primary">EXPORT CSV</Button>
                        </CSVLink>
                    )}
                </div>

                <Link className="AddButton" to="/logs/new">
                    <Button variant="primary">+ ADD VISITOR</Button>
                </Link>
            </div>
        </div>
    );
};
