import React from 'react';
import { Route } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import EditPage from './edit/EditPage';
import HomePage from './home/HomePage';
import LogPage from './logs/LogPage';
import SignInPage from './signin/SignInPage';
import NewVisitor from './newvisitor/NewVisitor';

const routes = () => {
    return (
        <div className="Routes">
            <Route exact path="/signin" component={SignInPage} />

            <AuthenticatedRoute exact path="/" component={HomePage} />
            <AuthenticatedRoute exact path="/logs" component={LogPage} />
            <AuthenticatedRoute path="/logs/edit/:id" component={EditPage} />
            <AuthenticatedRoute path="/logs/new" component={NewVisitor} />
        </div>
    );
};

export default routes;
