import React from 'react';
import Mic from '../../../logo/Mic';
import '../Tiles.scss';

export default props => {
    const { input } = props;
    const data = input;

    return (
        <div className="InterviewsThisYear">
            <div className="InterviewsThisYear__MicIcon">
                <Mic />
            </div>

            <div className="InterviewsThisYear__Words">
                <h1>{data} People</h1>
                <p>Interviews this Year</p>
            </div>
        </div>
    );
};
