import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { PrimaryLogo } from '../logo';
import LogIcon from '../logo/LogIcon';
import DashIcon from '../logo/DashIcon';
import './NavBar.scss';

const NavBar = () => {
    return (
        <div className="NavBar">
            <nav>
                <ul className="NavBar__navItems">
                    <div className="NavBar__logo">
                        <Link to="/">
                            <PrimaryLogo />
                        </Link>
                    </div>
                    <li>
                        <NavLink exact to="/" activeClassName="Selected">
                            <DashIcon />
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/logs" activeClassName="Selected">
                            <LogIcon />
                            Logs
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default NavBar;
