import React from 'react';

import LogoColor from './LogoColor';

export default ({ color, backgroundColor }) => {
    const mainStyle = {
        fill: color || LogoColor.white,
    };

    const bgStyle = {
        fill: backgroundColor || LogoColor.green,
    };

    return (
        <svg
            style={mainStyle}
            className="Logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 216 344.64"
        >
            <title>Mile Two</title>
            <rect
                className="background"
                style={bgStyle}
                x="4.93"
                y="5.54"
                width="205.06"
                height="333.79"
                rx="30.17"
                ry="30.17"
            />
            <path
                className="M"
                d="M48.42,58.66,61,86.28,73.71,58.66h9v69.05H73.4V79.92l-10.7,22.5H58.82l-10.71-23v48.41H38.8V58.66Z"
            />
            <path className="I" d="M101.33,58.66v69.05H92v-69Z" />
            <path className="L" d="M120.73,58.66v60.67h21v8.53H111.42v-69Z" />
            <path
                className="E"
                d="M176.9,58.66v8.53H157.2v22H171v8.22H157v22h20V128H147.89V58.81Z"
            />
            <path
                className="2"
                d="M70,195.21a45.14,45.14,0,0,1,38.33-18.78c19.7,0,36.15,9.78,36.15,31.35a26.79,26.79,0,0,1-11.48,23c-8.69,6.67-22.5,12.56-30.73,18.15s-10.08,10.86-10.08,16.45H144v19.71H68.44v-6.21c0-16,3.26-29.17,13.19-38,8.69-7.76,24.67-14.43,32.27-18.77s10.24-7.92,10.24-13.35c0-8.53-6.36-12.26-15-12.26a31.39,31.39,0,0,0-25,13.19Z"
            />
            <path
                className="border"
                d="M179.82,11.09a24.66,24.66,0,0,1,24.63,24.63V309.16a24.66,24.66,0,0,1-24.63,24.63H35.1a24.66,24.66,0,0,1-24.62-24.63V35.72A24.66,24.66,0,0,1,35.1,11.09H179.82m0-11.09H35.1A35.82,35.82,0,0,0-.61,35.72V309.16A35.82,35.82,0,0,0,35.1,344.88H179.82a35.83,35.83,0,0,0,35.72-35.72V35.72A35.83,35.83,0,0,0,179.82,0Z"
            />
        </svg>
    );
};
