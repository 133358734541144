import React from 'react';

export default {
    Sortable: () => {
        return (
            <svg className="Table__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M288 288H32c-28.4 0-42.8 34.5-22.6 54.6l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c20-20.1 5.7-54.6-22.7-54.6zM160 448L32 320h256L160 448zM32 224h256c28.4 0 42.8-34.5 22.6-54.6l-128-128c-12.5-12.5-32.8-12.5-45.3 0l-128 128C-10.7 189.5 3.6 224 32 224zM160 64l128 128H32L160 64z" />
            </svg>
        );
    },

    SortAscending: () => {
        return (
            <svg className="Table__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M32.032 224h255.93c28.425 0 42.767-34.488 22.627-54.627l-127.962-128c-12.496-12.496-32.758-12.497-45.255 0l-127.968 128C-10.695 189.472 3.55 224 32.032 224zM160 64l128 128H32L160 64z" />
            </svg>
        );
    },

    SortDescending: () => {
        return (
            <svg className="Table__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M287.968 288H32.038c-28.425 0-42.767 34.488-22.627 54.627l127.962 128c12.496 12.496 32.758 12.497 45.255 0l127.968-128C330.695 322.528 316.45 288 287.968 288zM160 448L32 320h256L160 448z" />
            </svg>
        );
    },
};
