export default {
    sort: (aArg, bArg, sortDirection) => {
        let a = aArg;
        let b = bArg;

        // handle nulls
        if (!a && !b) {
            return 0;
        }
        if (!b) {
            return -1;
        }
        if (!a) {
            return 1;
        }

        // trim if it's a string
        if (a.trim) {
            a = a.trim();
        }
        if (b.trim) {
            b = b.trim();
        }

        // uppercase if it's a string
        if (a.toLocaleUpperCase) {
            a = a.toLocaleUpperCase();
        }
        if (b.toLocaleUpperCase) {
            b = b.toLocaleUpperCase();
        }

        if (sortDirection === 'asc') {
            if (a.localeCompare) {
                return a.localeCompare(b);
            }
            return a < b ? -1 : 1;
        }
        // descending sort
        if (b.localeCompare) {
            return b.localeCompare(a);
        }
        return b < a ? -1 : 1;
    },
};
