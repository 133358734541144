import moment from 'moment';
import Api from './ApiEndpoints';

/* eslint-disable import/prefer-default-export */
/* eslint-disable no-await-in-loop */
async function putData(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    });
    return response.json();
}

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

const ApiFunctions = {
    async getEntry(id) {
        const url = Api.GET_ENTRY;

        try {
            const response = await fetch(`${url}${id}`);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'Interviews API: Error fetching the Visitor DB!',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },

    async getDailyVisitors() {
        const url = Api.GET_DAILY;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'WelcomeAdminAPI: Error fetching the Visitor DB!',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },

    async getAllInterviews() {
        const url = Api.GET_INTERVIEWS;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'Interviews API: Error fetching the Visitor DB!',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },

    async getAverageTime() {
        const url = Api.GET_TIME;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'WelcomeAdminAPI: Error fetching the Visitor DB!',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },

    async getAnnualVisitors() {
        const url = Api.GET_YEARLY;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'WelcomeAdminAPI: Error fetching the Visitor DB!',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },

    async getMonthlyVisitors() {
        const url = Api.GET_MONTH;
        try {
            const results = [];
            for (let i = 0; i <= 11; i += 1) {
                const response = await fetch(url.concat(i));
                if (response.status !== 200) {
                    return {
                        status: response.status,
                        message: 'WelcomeAdminAPI: Error fetching the Visitor DB!',
                        error: true,
                    };
                }
                const result = await response.json();
                results.push(result);
            }
            return results;
        } catch (e) {
            return [e];
        }
    },

    async getHourlyVisitors() {
        const url = Api.GET_ALL;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'WelcomeAdminAPI: Error fetching the Visitor DB!',
                    error: true,
                };
            }
            const results = await response.json();

            const hours = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

            for (let r = 0; r < results.length; r += 1) {
                const start = moment(results[r].login_date).hour();
                const end = moment(results[r].logout_date).hour();

                if (start <= 7 && end >= 7) {
                    hours[0] += 1;
                }
                if (start <= 8 && end >= 8) {
                    hours[1] += 1;
                }
                if (start <= 9 && end >= 9) {
                    hours[2] += 1;
                }
                if (start <= 10 && end >= 10) {
                    hours[3] += 1;
                }
                if (start <= 11 && end >= 11) {
                    hours[4] += 1;
                }
                if (start <= 12 && end >= 12) {
                    hours[5] += 1;
                }
                if (start <= 13 && end >= 13) {
                    hours[6] += 1;
                }
                if (start <= 14 && end >= 14) {
                    hours[7] += 1;
                }
                if (start <= 15 && end >= 15) {
                    hours[8] += 1;
                }
                if (start <= 16 && end >= 16) {
                    hours[9] += 1;
                }
                if (start <= 17 && end >= 17) {
                    hours[10] += 1;
                }
                if (start <= 18 && end >= 18) {
                    hours[11] += 1;
                }
            }

            return hours;
        } catch (e) {
            return [e];
        }
    },

    async sendEntry(values) {
        const url = Api.PUT_ENTRY;
        try {
            console.log(values);
            const result = await putData(url, values);
            return result;
        } catch (e) {
            return [e];
        }
    },

    async newEntry(values) {
        const url = Api.PUT_NEW_ENTRY;
        try {
            console.log(values);
            const result = await postData(url, values);
            return result;
        } catch (e) {
            return [e];
        }
    },

    async getAllVisitors() {
        const url = Api.GET_ALL;

        try {
            const response = await fetch(url);
            if (response.status !== 200) {
                return {
                    status: response.status,
                    message: 'WelcomeAdminAPI: Error fetching the Visitor DB!',
                    error: true,
                };
            }

            const result = await response.json();
            return result;
        } catch (e) {
            return [e];
        }
    },
};
export default ApiFunctions;
