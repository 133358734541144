/* eslint-disable camelcase */
// eslint-disable-next-line react/jsx-props-no-spreading

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import authService from './AuthService';

function isAuthenticated() {
    const id_token = authService.getToken();
    const expires_in = authService.getTokenExpiration();

    if (!id_token) {
        return false;
    }
    try {
        if (expires_in === 0) {
            return false;
        }
    } catch (err) {
        return false;
    }
    return true;
}

const AuthenticatedRoute = ({ component: Component, ...attrs }) => (
    <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attrs}
        render={props =>
            // eslint-disable-next-line react/jsx-props-no-spreading
            isAuthenticated() === true ? <Component {...props} /> : <Redirect to="/signIn" />
        }
    />
);

export default AuthenticatedRoute;
