import React, { useEffect, useState } from 'react';
import BusiestTimes from './chart/BusiestTimes';
import MonthlyChart from './chart/MonthlyChart';
import SignedIn from './signedIn/SignedIn';

import TimeInOffice from './tiles/timeInOffice/TimeInOffice';
import Interviews from './tiles/interviews/InterviewsThisYear';
import VisitorsThisYear from './tiles/visitorsThisYear/VisitorsThisYear';
import DailyTable from './daily/DailyTable';
import './HomePage.scss';
import ApiFunctions from '../api/WelcomeAdminAPI';

export default () => {
    const [timeInOffice, setTimeInOffice] = useState([]);
    const [timeInOfficeResponse, setTimeInOfficeResponse] = useState([]);

    const [interview, setInterview] = useState([]);
    const [interviewResponse, setInterviewResponse] = useState([]);

    const [visitorsThisYear, setVisitorsThisYear] = useState([]);
    const [visitorsThisYearResponse, setVisitorsThisYearResponse] = useState([]);

    const [signedIn, setSignedIn] = useState([]);
    const [signedInResponse, setSignedInResponse] = useState([]);

    const [yearGraph, setYearGraph] = useState([]);
    const [yearGraphResponse, setYearGraphResponse] = useState([]);

    const [hourGraph, setHourGraph] = useState([]);
    const [hourGraphResponse, setHourGraphResponse] = useState([]);

    // TimeinOffice API call
    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getAverageTime();
            if (response.error) {
                setTimeInOffice([]);
            } else {
                setTimeInOffice(response);
            }
            setTimeInOfficeResponse(response);
        }
        getList();
    }, []);

    // interview

    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getAllInterviews();
            if (response.error) {
                setInterview([]);
            } else {
                setInterview(response);
            }
            setInterviewResponse(response);
        }
        getList();
    }, []);

    // visitorsthisYear
    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getAnnualVisitors();
            if (response.error) {
                setVisitorsThisYear([]);
            } else {
                setVisitorsThisYear(response);
            }
            setVisitorsThisYearResponse(response);
        }
        getList();
    }, []);
    // signedIn
    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getAllVisitors();
            if (response.error) {
                setSignedIn([]);
            } else {
                setSignedIn(response);
            }
            setSignedInResponse(response);
        }
        getList();
        setInterval(getList, 30000);
    }, []);
    // yearly graph
    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getMonthlyVisitors();
            if (response.error) {
                setYearGraph([]);
            } else {
                setYearGraph(response);
            }
            setYearGraphResponse(response);
        }
        getList();
        setInterval(getList, 30000);
    }, []);
    // hourly graph
    useEffect(() => {
        async function getList() {
            const response = await ApiFunctions.getHourlyVisitors();
            if (response.error) {
                setHourGraph([]);
            } else {
                setHourGraph(response);
            }
            setHourGraphResponse(response);
        }
        getList();
        setInterval(getList, 30000);
    }, []);

    if (
        timeInOfficeResponse.error ||
        interviewResponse.error ||
        visitorsThisYearResponse.error ||
        signedInResponse.error ||
        yearGraphResponse.error ||
        hourGraphResponse.error
    ) {
        return <div className="HomeError"> Sorry, the Dash Data could not be retrieved. </div>;
    }
    return (
        <div className="HomePage">
            <div className="Row">
                <div className="HomePage__Tile">
                    <TimeInOffice input={timeInOffice} />
                </div>
                <div className="HomePage__Tile">
                    <Interviews className="HomePage__Tile" input={interview} />
                </div>
                <div className="HomePage__Tile">
                    <VisitorsThisYear className="HomePage__Tile" input={visitorsThisYear} />
                </div>
            </div>
            <div className="RowFlex">
                <div className="HomePage__MonthlyChart">
                    <MonthlyChart input={yearGraph} />
                </div>
                <div className="HomePage__SignedInVisitors">
                    <SignedIn input={signedIn} />
                </div>
            </div>
            <div className="RowFlex">
                <div className="HomePage__DailyTable">
                    <DailyTable />
                </div>
                <div className="HomePage__BusiestTimes">
                    <BusiestTimes input={hourGraph} />
                </div>
            </div>
        </div>
    );
};
