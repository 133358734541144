import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import SearchInput from '../searchinput/SearchInput';
import DatePicker from '../datepicker/DatePicker';
import Button from '../button/Button';
import 'react-dropdown/style.css';
import './filter.scss';

export default function Filter({ onChange }) {
    const history = useHistory();
    const [selectedSearchBox, setSelectedSearchBox] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedVisiting, setSelectedVisiting] = useState('');
    const [selectedReason, setSelectedReason] = useState('');

    const locations = ['444 E 2nd St.', '711 Monument Ave.'];

    const reason = ['Meeting', 'Interview', 'Visiting Employee', 'Building Maintenance'];

    const visiting = [
        'Jeff Graley',
        'Alex Morison',
        'Joe Krebs',
        'Jorge Sanchez',
        'Stacie Hoelscher',
        'Brad Gatchel',
        'Dave Best',
        'Michael Bowman',
        'Heather Gudorf',
        'Michael Heroux',
        'Jamie Howard',
        'Raphael Carranza',
        'Melissa Elliot',
        'Scott Jones',
        'Sarah Pleasant',
        'Jennifer Vasquez',
        'Amanda Walther',
    ];

    function clearSelections() {
        setSelectedSearchBox('');
        setSelectedStartDate(undefined);
        setSelectedEndDate(undefined);
        setSelectedLocation('');
        setSelectedVisiting('');
        setSelectedReason('');

        const query = {
            searchbox: '',
            startdate: '',
            enddate: '',
            location: '',
            visiting: '',
            reason: '',
        };
        onChange(query);
        history.push('/logs');
    }

    return (
        <div className="Top">
            <SearchInput
                onChange={searchtext => {
                    setSelectedSearchBox(searchtext);
                    const query = {
                        searchbox: searchtext,
                        startdate: selectedStartDate,
                        enddate: selectedEndDate,
                        location: selectedLocation,
                        visiting: selectedVisiting,
                        reason: selectedReason,
                    };
                    onChange(query);
                }}
                placeholder=""
            />
            <DatePicker
                fromHolder={selectedStartDate}
                toHolder={selectedEndDate}
                onChange={searchtext => {
                    const start = moment(searchtext.start).format('MM-DD-YYYY');
                    const end = moment(searchtext.end).format('MM-DD-YYYY');
                    setSelectedStartDate(start);
                    setSelectedEndDate(end);
                    const query = {
                        searchbox: selectedSearchBox,
                        startdate: start,
                        enddate: end,
                        location: selectedLocation,
                        visiting: selectedVisiting,
                        reason: selectedReason,
                    };

                    onChange(query);
                }}
            />
            <Dropdown
                onChange={searchtext => {
                    setSelectedLocation(searchtext.value);
                    const query = {
                        searchbox: selectedSearchBox,
                        startdate: selectedStartDate,
                        enddate: selectedEndDate,
                        location: searchtext.value,
                        visiting: selectedVisiting,
                        reason: selectedReason,
                    };

                    onChange(query);
                }}
                className="location"
                options={locations}
                placeholder="Location"
                value={selectedLocation}
            />

            <Dropdown
                onChange={searchtext => {
                    setSelectedVisiting(searchtext.value);
                    const query = {
                        searchbox: selectedSearchBox,
                        startdate: selectedStartDate,
                        enddate: selectedEndDate,
                        location: selectedLocation,
                        visiting: searchtext.value,
                        reason: selectedReason,
                    };
                    onChange(query);
                }}
                className="visiting"
                options={visiting}
                placeholder="Visiting"
                value={selectedVisiting}
            />

            <Dropdown
                onChange={searchtext => {
                    setSelectedReason(searchtext.value);
                    const query = {
                        searchbox: selectedSearchBox,
                        startdate: selectedStartDate,
                        enddate: selectedEndDate,
                        location: selectedLocation,
                        visiting: selectedVisiting,
                        reason: searchtext.value,
                    };
                    onChange(query);
                }}
                className="reason"
                options={reason}
                placeholder="Reason"
                value={selectedReason}
            />
            <div className="ClearAll">
                <Button variant="primary" value="Clear Selections" onClick={clearSelections}>
                    CLEAR
                </Button>
            </div>
        </div>
    );
}
