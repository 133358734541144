import React, { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import authService from '../AuthService';
import './SignIn.scss';

export default () => {
    const history = useHistory();
    const [login, setLogin] = useState(false);

    function onSignIn(response) {
        authService.storeGoogleResponseObject(response);
        history.push('/');
    }
    function responseGoogle() {
        authService.clearAttempt();
        history.push('/signin');
        setLogin(true);
    }

    return (
        <div className="SignInPage">
            <h1 className="SignInPage__Header">
                Welcome|<div className="SignInPage__Header-secondary">Admin</div>
            </h1>

            <div className="SignInPage__login-button">
                <GoogleLogin
                    clientId="455748735754-9i5j1o80dvirphdfrvrof01c08rmmsap.apps.googleusercontent.com"
                    buttonText="LOGIN WITH GOOGLE"
                    onSuccess={onSignIn}
                    onFailure={responseGoogle}
                />
            </div>
            {login && <p>Login Failed. Please Try Again.</p>}
        </div>
    );
};
