import React, { useState } from 'react';

import Sorter from './Sorter';
import TableHeaderCell from './TableHeaderCell';

import './Table.scss';

const Table = ({ columns, data }) => {
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);

    if (!data || data.length < 1 || !columns || columns.length < 1) {
        // Nothing to render!
        return null;
    }

    // Make a copy (shallow) of the array so we don't mess anything up
    const sorted = [...data].sort((a, b) => {
        if (!sortField || !sortDirection) {
            return 0;
        }

        return Sorter.sort(a[sortField], b[sortField], sortDirection);
    });

    return (
        <table className="Table">
            <thead>
                <tr>
                    {columns.map(column => {
                        return (
                            <TableHeaderCell
                                key={column.field}
                                field={column.field}
                                label={column.label}
                                sortable={column.sortable}
                                sortField={sortField}
                                sortDirection={sortDirection}
                                onChangeSort={({ field, direction }) => {
                                    setSortField(field);
                                    setSortDirection(direction);
                                }}
                            />
                        );
                    })}
                </tr>
            </thead>
            <tbody>
                {sorted.map((item, i) => {
                    return (
                        <tr key={`row-${i}`}>
                            {columns.map(column => {
                                let cellClass = null;
                                if (column.nowrap) {
                                    cellClass = 'Table__cell-nowrap';
                                }

                                let content = item[column.field];

                                if (column.renderer) {
                                    content = <column.renderer row={item} />;
                                }

                                return (
                                    <td className={cellClass} key={column.field}>
                                        {content}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
