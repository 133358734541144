import './Chart.scss';
import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';

// eslint-disable-next-line react/prefer-stateless-function
class Chart extends Component {
    render() {
        const { input } = this.props;

        const simpleChartData = {
            labels: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sept',
                'Oct',
                'Nov',
                'Dec',
            ],
            series: [input],
        };

        const options = {
            stackBars: true,
            height: '25%',
        };

        return (
            <div className="Chart">
                <h1 className="BarTitle">Visitors This Year</h1>
                <ChartistGraph data={simpleChartData} options={options} type="Bar" />
            </div>
        );
    }
}
export default Chart;
