import React, { useState } from 'react';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import ApiFunctions from '../api/WelcomeAdminAPI';
import Button from '../button/Button';
import './NewVisitor.scss';

export default () => {
    const [attempt, setAttempt] = useState({});
    const history = useHistory();

    let maxDate = new Date();
    maxDate = moment(maxDate).format('yyyy-MM-ddThh:mm');

    let now = new Date();
    now = moment(now).format('MM/DD/YYYY h:mm a');

    let newValue = {
        first_name: '',
        last_name: '',
        company_name: '',
        citizenship: '',
        reason_visiting: '',
        employee_escort: '',
        location: '',
        login_date: now,
        logout_date: now,
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        newValue = { ...attempt, [name]: value };
        setAttempt(newValue);
    };

    async function sendNew() {
        await ApiFunctions.newEntry(attempt);
        history.push('/logs');
    }

    return (
        <div className="NewVisitor">
            <h1>Add Visitor</h1>
            <h2>*All fields must be completed</h2>
            <form>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="fname">First name</label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            defaultValue=""
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="Group">
                        <label htmlFor="lname">Last name</label>
                        <input
                            type="text"
                            id="lname"
                            name="last_name"
                            defaultValue=""
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="company">Company</label>
                        <input
                            type="text"
                            id="company"
                            name="company_name"
                            defaultValue=""
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="Group">
                        <label htmlFor="citizenship">Citizenship</label>
                        <input
                            type="text"
                            id="citizenship"
                            name="citizenship"
                            defaultValue=""
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="visiting">Employee Escort</label>
                        <input
                            name="employee_escort"
                            defaultValue=""
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="Group">
                        <label htmlFor="reason">Reason for Visiting</label>
                        <input
                            name="reason_visiting"
                            defaultValue=""
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <div className="Textbox">
                            <label htmlFor="date">Time Signed In</label>
                            <input
                                type="datetime-local"
                                id="date"
                                name="login_date"
                                max={maxDate}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="Group">
                        <div className="Textbox">
                            <label htmlFor="date">Time Signed Out</label>
                            <input
                                type="datetime-local"
                                id="date"
                                name="logout_date"
                                max={maxDate}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="Row">
                    <div className="Group">
                        <label htmlFor="location">Location</label>
                        <input name="location" defaultValue="" onChange={handleInputChange} />
                    </div>
                </div>
            </form>
            <div className="Buttons">
                <Link className="SubmitButton" to="/logs">
                    <Button variant="primary" href="/logs" onClick={sendNew}>
                        SUBMIT
                    </Button>
                </Link>
                <Link className="CancelButton" to="/logs">
                    <Button variant="primary">CANCEL</Button>
                </Link>
            </div>
        </div>
    );
};
