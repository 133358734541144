import React from 'react';
import { useLocation } from 'react-router-dom';
import Routes from './routes';
import Header from './header/Header';
import Navbar from './navbar/NavBar';

import './App.scss';

const App = () => {
    const location = useLocation();
    return (
        <div exact className="App">
            {location.pathname !== '/signin' && <Header />}
            <Navbar />
            <Routes />
        </div>
    );
};
export default App;
