import React, { useState } from 'react';

import { ReactComponent as SearchIcon } from './search.svg';

import './SearchInput.scss';

export default ({ id, onChange, placeholder }) => {
    const [value, setValue] = useState('');

    const placeHolderText = placeholder || '';
    const searchId = id || 'SearchInput';

    const handleChange = e => {
        setValue(e.target.value);
        onChange(e.target.value);
    };

    return (
        <div className="SearchInput">
            <input
                type="text"
                id={searchId}
                placeholder={placeHolderText}
                value={value}
                onChange={handleChange}
                autoComplete="off"
            />
            <SearchIcon />
        </div>
    );
};
