/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import ApiFunctions from '../../api/WelcomeAdminAPI';
import './Daily.scss';

export default () => {
    const [dailyNums, setDailyNums] = useState([]);
    useEffect(() => {
        async function getList() {
            const list = await ApiFunctions.getDailyVisitors();
            setDailyNums(list);
        }
        getList();
        setInterval(getList, 30000);
    }, []);

    return (
        <div className="DailyTable">
            <h1>Reason For Visiting</h1>
            <table>
                <tbody>
                    <tr>
                        <td>Interview:</td>
                        <td>{dailyNums[0]} times</td>
                    </tr>
                    <tr>
                        <td>Meeting:</td>
                        <td>{dailyNums[2]} times</td>
                    </tr>
                    <tr>
                        <td>Visiting Employee:</td>
                        <td>{dailyNums[3]} times</td>
                    </tr>
                    <tr>
                        <td>Building Maintenance:</td>
                        <td>{dailyNums[1]} times</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
