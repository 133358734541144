import React from 'react';
import User from '../../../logo/UserIcon';
import '../Tiles.scss';

export default props => {
    const { input } = props;
    const data = input;

    return (
        <div className="VisitorsThisYear">
            <div className="VisitorsThisYear__PersonIcon">
                <User />
            </div>

            <div className="VisitorsThisYear__Words">
                <h1>{data} People</h1>
                <p>People This Year</p>
            </div>
        </div>
    );
};
