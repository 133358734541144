import React from 'react';
import { useHistory } from 'react-router-dom';
import authService from '../AuthService';
import Icon from '../logo/LogoutIcon';

import './Header.scss';

const Header = () => {
    const history = useHistory();
    const logout = () => {
        localStorage.clear();
        history.push('/signin');
    };

    return (
        <header className="Header">
            <p className="Header__Welcome-Message">Welcome, {authService.getUsername()}!</p>

            <a href="/signin" onClick={logout} className="Header__Welcome-Icon">
                Logout <Icon />
            </a>
        </header>
    );
};
export default Header;
