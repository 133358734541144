export default {
    GET_ALL: '/api/logs',
    GET_INTERVIEWS: '/api/interviews',
    GET_YEARLY: '/api/yearly',
    GET_TIME: '/api/time',
    GET_DAILY: '/api/daily',
    GET_ENTRY: '/api/visitor/',
    GET_MONTH: '/api/monthly/',

    PUT_NEW_ENTRY: '/api/new',
    PUT_ENTRY: '/api/edit',
};
